import React, { useState, useEffect, useRef, useMemo } from 'react';
import Messages from 'components/Messages';
import Message from 'components/Message';
import { useAuth } from 'contexts/AuthContext';
import Input from 'components/Input';
import { useChat } from 'contexts/ChatContext';
import { useLaunch } from 'contexts/LaunchContext';
import PlansRedirect from 'views/stripe/PlansRedirect';
import { db, storage, analytics } from 'lib/firebase';
import {
	addDoc,
	collection,
	doc,
	getDoc,
	getDocs,
	updateDoc,
	orderBy,
	query,
	serverTimestamp,
} from 'firebase/firestore';
import { Helmet } from 'react-helmet';
import Learning from '../../assets/img/modes/Learning Mode.png';
import Coding from '../../assets/img/modes/Coding Mode.png';
import Creative from '../../assets/img/modes/Creative Mode.png';
import Maths from '../../assets/img/modes/Math and Science Mode.png';
import Solve from '../../assets/img/modes/Solve It Mode.png';
import Writing from '../../assets/img/modes/Writing Mode.png';
import Play from '../../assets/img/modes/play.png';
import CreativeModeSelection from './CreativeModeSelection';
import { uploadBytes, getDownloadURL, ref } from 'firebase/storage';
import { randomString } from 'helpers/creativeImage';
import axios from 'axios';
import base64 from 'base64-js';
import { config } from 'constants/stripe';
import { logEvent } from 'firebase/analytics';

const ImageModType = ['textToImage', 'imageToImage'];

export default function ChatbotComponent({
	chatBotChild,
	callChatBotChild,
	setIsVisible,
	setBottomShare,
	toggleCancelButton,
}) {
	const { mode, sendMessage } = useChat();
	const { launchPadData, characterData, s_char } = useLaunch();
	const [streamingMessage, setStreamingMessage] = useState(null);
	const [subscribe, setSubscribe] = useState(false);
	const [replying, setReplying] = useState(false);
	const [loading, setLoading] = useState(false);
	const { user } = useAuth();

	// const docId = characterData
    // ? launchPadData
    //     ? `${user?.uid || 'guest'}-${characterData?.name?.replace(/\//g, '').replace(/ /g, '-')}-${characterData?.id?.substring(0, 7) || '0000000'}-${launchPadData?.name?.replace(/\//g, '').replace(/ /g, '-')}`
    //     : `${user?.uid || 'guest'}-${characterData?.name?.replace(/ /g, '-').replace(/\//g, '')}-${mode?.key || 'defaultMode'}`
    // : launchPadData
    //     ? `${user?.uid || 'guest'}-${launchPadData?.name?.replace(/\//g, '').replace(/ /g, '-')}`
    //     : `${user?.uid || 'guest'}-${mode?.key || 'defaultMode'}`;

	// console.log(docId, launchPadData, user.uid, characterData, mode)

	const [messages, setMessages] = useState([]);
	const [enter, setEnter] = useState(true);
	const [uploadedImage, setUploadedImage] = useState(null);
	const [creativeImageModeType, setCreativeImageModeType] = useState(
		ImageModType[0]
	);
	const [loadedImages, setLoadedImages] = useState(0);
	const [isShareModeActive, setShareModeActive] = useState(false);
	const [writingPrefix, setWritingPrefix] = useState('');
	const [isVisible, setIsVisibles] = useState(false);
	const [isGradeLevel, setIsGradeLevel] = useState(false);
	const [isTone, setIsTone] = useState(false);
	const [isLength, setIsLength] = useState(false);
	const [isPersona, setIsPersona] = useState(false);
	const [isInternet, setIsInternet] = useState(false);

	const docId = useMemo(() => {
		const uid = user?.uid || 'guest';
		const formatName = (name) => name?.replace(/\//g, '').replace(/ /g, '-') || '';
		return characterData
		  ? launchPadData
		  ? `${uid}-${formatName(characterData.name)}-${characterData?.id?.substring(0, 7) || '0000000'}-${formatName(launchPadData.name)}`
		  : `${uid}-${formatName(characterData.name)}-${mode?.key || 'defaultMode'}`
		  : launchPadData
			? `${uid}-${formatName(launchPadData.name)}`
			: `${uid}-${mode?.key || 'defaultMode'}`;
	}, [characterData, launchPadData, mode, user]);

	  
	const docRef = doc(db, 'bot-chats', docId);


	const messageChild = React.useRef(null);
	const toggleShareChild = React.useRef(null);
	let shareVisibilityCounter = false;

	React.useEffect(() => {
		chatBotChild.current = dummyFunction;
	}, []);

	function dummyFunction() {
		shareVisibilityCounter = !shareVisibilityCounter;
		shareVisibilityCounter
			? messageChild.current()
			: toggleShareChild.current();
	}

	function splitAndRandomlySelect(inputString) {
		// Match segments followed by "!", ".", or "?" or the end of the string
		const segments = inputString.match(/[^!?\.]*[!?\.]?/g).filter(segment => segment.trim().length > 0);

		// If there are no segments, return an empty string
		if (segments.length === 0) {
			return "";
		}

		// Randomly select an index
		const randomIndex = Math.floor(Math.random() * segments.length);

		// Return the randomly selected segment
		return segments[randomIndex].trim();
	}

	const typeMessage = (message, delay) => {
		return new Promise((resolve) => {
			let txt = '';
			let words = message.split(' '); // Split the message into words

			const addWord = (i) => {
				txt += words[i] + ' '; // Add the next word and a space
				setStreamingMessage(txt);
				scrollToBottom(true);
				if (i === words.length - 1) resolve();
			};

			for (let i in words) {
				setTimeout(() => addWord(i), i * delay);
			}
		});
	};

	// Gets all the messages of the user from firestore
	useEffect(() => {
		const options = query(
			collection(docRef, 'messages'),
			orderBy('timestamp')
		);
		getDocs(options).then( async (snapshot) => {
			setMessages(snapshot.docs.map((doc) => doc.data()));
			// console.log(
			// 	'Messages',
			// 	snapshot.docs.map((doc) => doc.data()),
			// 	snapshot.docs.map((doc) => doc.data()).length
			// );
			if (snapshot.docs.map((doc) => doc.data()).length === 0) {
				let text;
				if (characterData?.creator === "creator") {
					text = splitAndRandomlySelect(characterData.intro); 
				}else {
					text = characterData?.intro; 
				}
				if (characterData && launchPadData) {
					text = text + " " + launchPadData.intro
					await typeMessage(text, 70);
					const chatbotMessage = createMessage('chatbot', text);
					if (chatbotMessage.text != null) {
						setStreamingMessage(null);
						setMessages((messages) => [...messages, chatbotMessage]);
						await saveMessagesToDb(chatbotMessage);
						setReplying(false);
					}
				} else if (characterData) {
					await typeMessage(text, 70);
					const chatbotMessage = createMessage('chatbot', text);
					if (chatbotMessage.text != null) {
						setStreamingMessage(null);
						setMessages((messages) => [
							...messages,
							chatbotMessage,
						]);
						await saveMessagesToDb(chatbotMessage);
						setReplying(false);
					}
				}
			}
		});
	}, [s_char, launchPadData, characterData]);

	const handleSenseiIntro = async () => {
		let first = `Welcome ${
			user?.displayName.split(' ')[0] || ''
		}! I'm Sensei, the world's first super-powered AI designed to be your personal tutor… I customize to you! Student-built, astronaut-approved.`;

		await typeMessage(first, 70);
		const chatbotMessage = createMessage('chatbot', first);
		if (chatbotMessage.text != null) {
			setStreamingMessage(null);
			setMessages((messages) => [...messages, chatbotMessage]);
			await saveMessagesToDb(chatbotMessage);
			setReplying(false);
		}

		let second =
			`You're currently chatting with me in Learning Mode. If you want me to transform into another avatar, click my 'Sensei' icon above.`;
		await typeMessage(second, 70);
		const chatbotMessage2 = createMessage('chatbot', second);
		if (chatbotMessage2.text != null) {
			setStreamingMessage(null);
			setMessages((messages) => [...messages, chatbotMessage2]);
			await saveMessagesToDb(chatbotMessage2);
			setReplying(false);
		}

		let third =
			'To rocket fuel your other projects, go to Launchpad in the menu.';
		await typeMessage(third, 70);
		const chatbotMessage3 = createMessage('chatbot', third);
		if (chatbotMessage3.text != null) {
			setStreamingMessage(null);
			setMessages((messages) => [...messages, chatbotMessage3]);
			await saveMessagesToDb(chatbotMessage3);
			setReplying(false);
		}

		const updateUserProfileSenseiIntro = async (uid) => {
			const docRef = doc(db, 'userProfiles', uid);
			await updateDoc(docRef, { sensei_intro: true });
		};
		await updateUserProfileSenseiIntro(user.uid);
	};

	useEffect(() => {
		const isSenseiIntro = async (uid) => {
			const docRef = doc(db, 'userProfiles', uid);
			const docSnap = await getDoc(docRef);
			return docSnap.data().sensei_intro;
		};

		const getSensei = async () => {
			let sensei = await isSenseiIntro(user.uid);
			if (sensei === false && mode.key === 'learning') {
				handleSenseiIntro();
			}
		}

		if (user && user.uid) {
			getSensei();
		}

	}, []);

	const userInternet = async () => {
		const docRef = doc(db, 'userProfiles', user.uid);
		const docSnap = await getDoc(docRef);

		if (docSnap && docSnap.exists()) {
			return docSnap.data().internet || false;
		}
	};

	// opens a websocket and stream the message from the server on screen
	const connectAndReceiveData = async (text, uid) => {
		// const uri =
		// 	'wss:///wtjx3hxx-8000.euw.devtunnels.ms/api/v1/chat/ask_stream_ws';
		// const uri2 =
		// 	'wss:///wtjx3hxx-8000.euw.devtunnels.ms/api/v1/browser/browser_ws';
		// const uri =
		// 	'ws:///127.0.0.1:8000/api/v1/chat/ask_stream_ws';
		// const uri2 =
		// 	'ws:///127.0.0.1:8000/api/v1/browser/browser_ws';
		const uri =
			'wss://learningwithaiv1-t45fnh5fzq-uc.a.run.app/api/v1/chat/ask_stream_ws';
		const uri2 =
			'wss://learningwithaiv1-t45fnh5fzq-uc.a.run.app/api/v1/browser/browser_ws';
		// const uri = 'ws:///127.0.0.1:8000/api/v1/chat/ask_stream_ws';
		const firstName = user.displayName.split(' ')[0] || '';
		// const promt =
		// 	mode.prefix + firstName + ' .' + ' ' + firstName + ' say - ' + text;
		let ask_more =
			'It is *essential* to ask follow-up questions after answering mine to help me explore further. ';
		const prompt = text;
		const system_prompt = characterData
				? writingPrefix
					? launchPadData
						? writingPrefix +
						  ask_more +
						  'My name is ' +
						  firstName +
						  '. ' +
						  launchPadData.prefix
						: writingPrefix + ask_more + 'My name is ' + firstName
					: launchPadData
					? characterData.prompts +
					  ask_more +
					  'My name is ' +
					  firstName +
					  '. ' +
					  launchPadData.prefix
					: characterData.prompts +
					  ask_more +
					  'My name is ' +
					  firstName
				: writingPrefix
				? launchPadData
					? writingPrefix + firstName + '. ' + launchPadData.prefix
					: writingPrefix + firstName
				: launchPadData
				? mode.prefix + firstName + '. ' + launchPadData.prefix
				: mode.prefix + firstName;
		const title = mode.name

		const data = {
			uid: uid,
			chat_space_id: docId,
			prompt: prompt,
			system_prompt: system_prompt,
			title: title,
		};

		const data2 = {
			uid: uid,
			chat_space_id: docId,
			query: prompt,
			additional_system_content: system_prompt,
		};

		const isInternet = await userInternet();
		const socket = new WebSocket(isInternet ? uri2 : uri);
		socket.onopen = () => {
			socket.send(JSON.stringify(isInternet ? data2 : data));
		};
		return new Promise((resolve) => {
			let messageList = [];
			let responseText = '';
			let closeText = '';

			socket.onclose = (event) => {
				closeText =
					'WebSocket closed unexpectedly. Code: ' +
					event.code +
					', Reason: ' +
					event.reason;
				resolve({ responseText, closeText });
			};

			socket.onerror = (event) => {
				console.error('WebSocket error observed:', event);
			};

			socket.onmessage = (event) => {
				setLoading(false);
				const message = JSON.parse(event.data);

				if (message.status === 'done') {
					resolve({ responseText, closeText });
				} else {
					responseText += isInternet
						? message.result
						: message.response;
					setStreamingMessage(responseText);
					messageList.push(message);
				}
			};
		});
	};

	// saves the user and chatbot message to firestore
	const saveMessagesToDb = (...messages) => {
		const conversationRef = collection(docRef, 'messages');
		return Promise.all(
			messages.map(async (message) => {
				return await addDoc(conversationRef, message);
			})
		);
	};

	// returns true if user is subscribed
	const fetchUserProfileSubscribed = async (uid) => {
		const docRef = doc(db, 'userProfiles', uid);
		const docSnap = await getDoc(docRef);
		return docSnap.data().is_subscribed;
	};

	// return the amount of questions a user has asked
	const fetchUserProfileQuestionCount = async (uid) => {
		const docRef = doc(db, 'userProfiles', uid);
		const docSnap = await getDoc(docRef);
		return docSnap.data().question_count || 0;
	};

	// return the number of Images processed by the db
	const fetchUserProfileImageCount = async (uid) => {
		const docRef = doc(db, 'userProfiles', uid);
		const docSnap = await getDoc(docRef);
		return docSnap.data().image_count;
	};

	//updates the number if images generated by the user
	const updateUserProfileImageCount = async (uid, num) => {
		const docRef = doc(db, 'userProfiles', uid);
		await updateDoc(docRef, { image_count: num });
	};

	//updates the number if images generated by the user
	const updateUserProfileCharacterCount = async (uid, data) => {
		const docRef = doc(db, 'userProfiles', uid);
		const docSnap = await getDoc(docRef);
		const char = docSnap.data().char_list || [];
		if (!char.includes(data)) {
			// add id to char_id if it's not already there
			char.push(data);
		}
		await updateDoc(docRef, { char_list: char });
	};

	// create an object of the text input of the bot or user
	const createMessage = (sender, text, imageUrl = null) => {
		return {
			type: sender,
			text: text || '',
			imageUrl: imageUrl,
			timestamp: serverTimestamp(),
		};
	};

	// creates an image url using firebase getDownloadUrl
	// returns the url
	async function getImageUrl(imageData, uid) {
		const imageName = `image_${new Date().toISOString()}_${randomString(
			10
		)}.png`;
		const blob = new Blob([imageData], { type: 'image/png' });

		const storageRef = ref(storage, `images/${uid}/${imageName}`);
		await uploadBytes(storageRef, blob);
		const imageUrl = await getDownloadURL(storageRef);

		return imageUrl;
	}

	async function generateImageAndUpload(uid, prompt) {
		const imageDataBase64 = await generateImage(prompt);
		const imageData = base64.toByteArray(imageDataBase64);
		const url = await getImageUrl(imageData, uid);
		return url;
	}

	async function generateImage(prompt) {
		const generateData = {
			prompt: prompt + ', 4k photo',
		};

		try {
			const response = await axios.post(
				`https://learningwithaiv1-t45fnh5fzq-uc.a.run.app/api/v1/genimage1/generate_image`,
				// `http://127.0.0.1:8000/api/v1/genimage1/generate_image`,
				generateData,
				config
			);
			const imageDataBase64 = response.data.output;
			return imageDataBase64;
		} catch (error) {
			if (error.code === 'ERR_NETWORK') {
				alert('An error occured. Message or Image not appropriate');
				setReplying(false);
				setLoading(false);
			}
			console.error(error);
		}
	}

	// return the url of the image generated from image to image
	async function generateTextToImageAndUpload(imageFile, text, uid) {
		const imageDataBase64 = await textAndImageToImage(imageFile, text, uid);
		const imageData = base64.toByteArray(imageDataBase64);
		const url = await getImageUrl(imageData, uid);
		return url;
	}

	// returns the image in base64
	async function textAndImageToImage(imageFile, text, uid) {
		return new Promise((resolve, reject) => {
			// Convert the image File object to base64
			const reader = new FileReader();
			reader.readAsDataURL(imageFile);
			reader.onload = async () => {
				const base64Image = reader.result.split(',')[1];

				// Prepare the request data
				const request_data = {
					uid: uid,
					engine_id: 'stable-diffusion-v1-5',
					text_prompts: [{ text: text, weight: 1 }],
					init_image: base64Image,
					init_image_mode: 'IMAGE_STRENGTH',
					image_strength: 0.35,
					cfg_scale: 7,
					clip_guidance_preset: 'FAST_BLUE',
					samples: 1,
					steps: 30,
				};

				try {
					// Send the request to the API
					const response = await axios.post(
						'https://learningwithaiv1-t45fnh5fzq-uc.a.run.app/api/v1/genimage/image-to-image',
						// 'http://127.0.0.1:8000/api/v1/genimage/image-to-image',
						request_data,
						config
					);
					// Handle the response
					const imageDataBase64 = response.data.image_data;
					resolve(imageDataBase64);
				} catch (error) {
					if (error.code === 'ERR_NETWORK') {
						alert(
							'An error occured. Message or Image not appropriate'
						);
						setReplying(false);
						setLoading(false);
					}
					console.error(error);
					reject(error);
				}
			};
			reader.onerror = (error) => {
				console.error('Error reading image file:', error);
				reject(error);
			};
		});
	}

	// handles the send message function
	const handleSendMessage = async (message) => {
		setStreamingMessage(null);
		let studentMessage;
		logEvent(analytics, 'mode_used', { mode_name: mode.key }); // Log the event with mode

		//checks if the creative image mode is image to image
		if (uploadedImage) {
			const imageUrl = await getImageUrl(uploadedImage, user.uid);
			studentMessage = createMessage(
				'student',
				message.actualValue,
				imageUrl
			);
			setMessages((messages) => [...messages, studentMessage]);
			// Reset the uploaded image state
			setUploadedImage(null);
			setCreativeImageModeType(ImageModType[1]);
		} else {
			studentMessage = createMessage('student', message.actualValue);
			setMessages((messages) => [...messages, studentMessage]);
			setCreativeImageModeType(ImageModType[0]);
		}

		setLoading(true);

		// Checks if user is subscribed
		if (await fetchUserProfileSubscribed(user.uid)) {
			setReplying(true);

			// checks if user is in creativeImage mode
			if (mode.key === 'creativeImage') {
				// a tenary operator that calls different function based on
				// if it text-to-image or image-to-image
				const imageUrl = uploadedImage
					? await generateTextToImageAndUpload(
							uploadedImage,
							message.actualValue,
							user.uid
					  )
					: await generateImageAndUpload(
							user.uid,
							message.actualValue
					  );

				// creates the chatbot message object
				const chatbotMessage = createMessage('chatbot', null, imageUrl);

				// updates the Messages array
				setMessages((messages) => [...messages, chatbotMessage]);

				// sends message to firestore
				sendMessage(studentMessage, chatbotMessage, docRef).then(() => {
					setReplying(false);
					setLoading(false);
					// logs image_generated event
					logEvent(analytics, 'image_generated', {
						image_gen_user_id: user.uid,
					});
				});
				// ...
			} else {
				// ... The existing code for text responses
				// gets the text response from the server
				const { responseText, closeText } = await connectAndReceiveData(
					message.actualValue,
					user.uid
				);

				if (closeText === 'WebSocket closed unexpectedly.') {
					console.log(
						'WebSocket closed unexpectedly. Please try again.'
					);
				}
				// creates the chatbot message object
				const chatbotMessage = createMessage('chatbot', responseText);

				// checks if the server send an empty string
				if (chatbotMessage.text != null) {
					setStreamingMessage(null);
					// updates the Messages array
					setMessages((messages) => [...messages, chatbotMessage]);
					// sends message to firestore
					sendMessage(studentMessage, chatbotMessage, docRef).then(
						() => {
							setReplying(false);
							logEvent(analytics, 'questions_asked', {
								ques_asked_user_id: user.uid,
							});
						}
					);
				}
			}
		} else {
			// gets the chatbot question count associated with the user
			let count = await fetchUserProfileQuestionCount(user.uid);
			if (count <= 25) {
				setReplying(true);

				// checks if in creativeImage mode
				if (mode.key === 'creativeImage') {
					// gets the generated image count
					let image_count =
						(await fetchUserProfileImageCount(user.uid)) || 0;

					if (image_count <= 4) {
						const imageUrl = uploadedImage
							? await generateTextToImageAndUpload(
									uploadedImage,
									message.actualValue,
									user.uid
							  )
							: await generateImageAndUpload(
									user.uid,
									message.actualValue
							  );

						const chatbotMessage = createMessage(
							'chatbot',
							null,
							imageUrl
						);
						setMessages((messages) => [
							...messages,
							chatbotMessage,
						]);
						sendMessage(
							studentMessage,
							chatbotMessage,
							docRef
						).then(() => {
							setReplying(false);
							setLoading(false);
							image_count += 1;
							updateUserProfileImageCount(user.uid, image_count);
							logEvent(analytics, 'image_generated', {
								image_gen_user_id: user.uid,
							});
						});
					} else {
						setLoading(false);
						setSubscribe(true);
					}
				} else {
					const { responseText, closeText } =
						await connectAndReceiveData(
							message.actualValue,
							user.uid
						);

					if (closeText === 'WebSocket closed unexpectedly.') {
						console.log(
							'WebSocket closed unexpectedly. Please try again.'
						);
					}
					const chatbotMessage = createMessage(
						'chatbot',
						responseText
					);
					if (chatbotMessage.text != null) {
						if (characterData) {
							updateUserProfileCharacterCount(
								user.uid,
								characterData
							);
						}
						setStreamingMessage(null);
						setMessages((messages) => [
							...messages,
							chatbotMessage,
						]);
						sendMessage(
							studentMessage,
							chatbotMessage,
							docRef
						).then(() => {
							setReplying(false);
							logEvent(analytics, 'questions_asked', {
								ques_asked_user_id: user.uid,
							});
						});
					}
				}
			} else if (count > 25) {
				setLoading(false);
				setSubscribe(true);
			}
		}
	};

	// a reference to an element that be used to cause the page to automatically scroll to the last message
	const scrollRef = useRef();

	useEffect(() => {
		scrollToBottom(true);
	}, [messages, loading, loadedImages]);

	useEffect(() => {
		scrollToBottom(true);
	}, []);

	useEffect(() => {
		scrollToBottom(false);
	}, [streamingMessage]);

	const [width, setWidth] = useState(window.innerWidth);

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}

	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	const isMobile = width <= 800;

	useEffect(() => {
		if (!isMobile) {
			const sidebarWidth = 300;
			const contentWidth = width - sidebarWidth;
			const left = width - contentWidth;
			const backgroundMode = document.querySelector('.background-mode');
			if (backgroundMode) {
				backgroundMode.style.width = `${contentWidth}px`;
				backgroundMode.style.left = `${left}px`;
			}
		}
		if (isMobile) {
			const backgroundMode = document.querySelector('.background-mode');
			if (backgroundMode) {
				backgroundMode.style.width = `100%`;
				backgroundMode.style.left = `0px`;
			}
		}
	}, [width, isMobile]);

	const isUserAtBottom = (container) => {
		const { scrollTop, clientHeight, scrollHeight } = container;
		let threshold = 50;
		if (isMobile) {
			threshold = 150;
		}
		return scrollHeight - scrollTop - clientHeight <= threshold;
	};

	const scrollToBottom = (force = false) => {
		const scrollContainer = document.getElementById('scrollable-container');
		if (force || isUserAtBottom(scrollContainer)) {
			scrollRef.current.scrollIntoView();
		}
	};

	const getBackgroundUrl = (mode) => {
		if (mode.key === 'learning') {
			return Learning;
		} else if (mode.key === 'creative') {
			return Creative;
		} else if (mode.key === 'solver') {
			return Solve;
		} else if (mode.key === 'math') {
			return Maths;
		} else if (mode.key === 'writing') {
			return Writing;
		} else if (mode.key === 'play') {
			return Play;
		} else {
			return Coding;
		}
	};

	const handleCreativeMode = () => {
		setEnter(false);
	};

	const handleRegenerate = async () => {
		setReplying(true);
		setLoading(true);
		let imageUrl;
		const tools = messages.filter((tool) => tool.type.includes('student'));
		let msg = tools[tools.length - 1].text;
		if (await fetchUserProfileSubscribed(user.uid)) {
			if (creativeImageModeType === ImageModType[0]) {
				imageUrl = await generateImageAndUpload(user.uid, msg);
			} else {
				imageUrl = generateTextToImageAndUpload(
					tools[tools.length - 1].imageUrl,
					msg,
					user.uid
				);
			}

			const chatbotMessage = createMessage('chatbot', null, imageUrl);
			setMessages((messages) => [...messages, chatbotMessage]);
			setLoading(false);
			saveMessagesToDb(chatbotMessage).then(() => {
				setReplying(false);
				logEvent(analytics, 'image_generated', {
					image_gen_user_id: user.uid,
				});
			});
			return true;
		} else {
			let image_count = (await fetchUserProfileImageCount(user.uid)) || 0;
			if (image_count <= 4) {
				if (creativeImageModeType === ImageModType[0]) {
					imageUrl = await generateImageAndUpload(user.uid, msg);
				} else {
					imageUrl = generateTextToImageAndUpload(
						tools[tools.length - 1].imageUrl,
						msg,
						user.uid
					);
				}

				const chatbotMessage = createMessage('chatbot', null, imageUrl);
				setMessages((messages) => [...messages, chatbotMessage]);
				setLoading(false);
				saveMessagesToDb(chatbotMessage).then(() => {
					setReplying(false);
					image_count += 1;
					updateUserProfileImageCount(user.uid, image_count);
					logEvent(analytics, 'image_generated', {
						image_gen_user_id: user.uid,
					});
				});
			} else {
				setSubscribe(true);
				return false;
			}
		}
	};
	const isShareApiSupported = () => {
		return navigator.share !== undefined;
	};

	const handleShareMode = (isShareMode) => {
		setShareModeActive(isShareMode);
	};

	let parentDocId = '';

	const saveShareMessagesToDb = (messages) => {
		const docRef = doc(db, 'public-share-messages', docId);
		const conversationRef = collection(docRef, 'messages');

		parentDocId = docRef._key.path.segments[1];

		return Promise.all(
			messages.map(async (message) => {
				return await addDoc(conversationRef, message);
			})
		);
	};

	const generateShareLink = async (data) => {
		var uid = user.uid;
		var docId = randomString(10);
		// Extracting base URL correctly
		var url = window.location.origin; 
		var name = user.displayName;

		data.forEach((element) => {
			element.name = name;
		});

		data.forEach((element) => {
			element.uid = uid;
			element.docId = docId;
			element.header = characterData?.name || null;
			element.header_img = characterData?.image || null;
		});

		await saveShareMessagesToDb(data);

		setShareModeActive(false);

		var link = url + '/share-chat/' + parentDocId + '/' + docId;

		if (isShareApiSupported()) {
			try {
				await navigator.share({
					title: 'Learning with AI',
					url: link,
				});
				console.log('Successfully shared');
			} catch (error) {
				console.error('Error sharing:', error);
			}
		} else {
			console.error('Web Share API is not supported');
		}

		logEvent(analytics, 'share_link_generated', {
			share_gen_user_id: user.uid,
		});

		return link
	};

	const handleClickOutside = () => {
		setIsVisibles(false);
		setIsGradeLevel(false);
		setIsTone(false);
		setIsLength(false);
		setIsPersona(false);
		setIsInternet(false);
	};

	return (
		<>
			<Helmet>
				<title>Chat | Learning With AI</title>
			</Helmet>
			<div onClick={handleClickOutside}>
				<div
					className="background-mode-wrapper"
					style={{
						position: 'relative',
						width: '100%',
						height: '100%',
						overflow: 'hidden',
						zIndex: 0,
					}}
				>
					<div className="chat_page_container_ll chat-component">
						<div
							className="messages-container p-0 pb-3 pb-md-5"
							id="scrollable-container"
							style={{
								height: '85vh',
								overflow: 'auto',
							}}
						>
							<div
								className="chat-details"
								style={{ marginBottom: '7vh' }}
							>
								<img
									src={
										launchPadData
											? launchPadData.image
											: getBackgroundUrl(mode)
									}
									alt="sensei"
									className="messages-img mb-3"
								/>
								<h1>
									{launchPadData
										? launchPadData.name
										: mode.fullName}
								</h1>
								<p>
									{launchPadData
										? launchPadData.description
										: mode.description}
								</p>
							</div>
							<PlansRedirect subscribe={subscribe} mode={mode} />
							{mode.key === 'creative' && (
								<CreativeModeSelection
									enter={enter}
									handleEnter={handleCreativeMode}
								/>
							)}

							<Messages
								conversation={messages}
								imageSenderMap={{
									chatbot: './chat-gpt-logo.jpeg',
									student: user.photoURL || './profile.jpeg',
								}}
								shareCallback={generateShareLink}
								handleShareMode={handleShareMode}
								handleRegenerate={handleRegenerate}
								setLoadedImages={setLoadedImages}
								messageChild={messageChild}
								disableShareChild={toggleShareChild}
								callChatBotChild={callChatBotChild}
								isMobile={isMobile}
								setIsVisible={setIsVisible}
								setBottomShare={setBottomShare}
								toggleCancelButton={toggleCancelButton}
							/>
							{loading && (
								<Message loading={loading} from="chatbot" />
							)}
							{/* used to display streaming message */}
							{streamingMessage != null && (
								<Message
									from="chatbot"
									content={streamingMessage}
								/>
							)}

							<div
								ref={scrollRef}
								style={{ marginTop: '80px' }}
							/>
						</div>
						{!isShareModeActive && (
							<Input
								useable={!replying}
								onSubmit={handleSendMessage}
								mode={mode}
								setUploadedImage={setUploadedImage}
								uploadedImage={uploadedImage}
								setWritingPrefix={setWritingPrefix}
								isVisible={isVisible}
								setIsVisible={setIsVisibles}
								isGradeLevel={isGradeLevel}
								setIsGradeLevel={setIsGradeLevel}
								isTone={isTone}
								setIsTone={setIsTone}
								isLength={isLength}
								setIsLength={setIsLength}
								isPersona={isPersona}
								setIsPersona={setIsPersona}
								isInternet={isInternet}
								setIsInternet={setIsInternet}
							/>
						)}
					</div>
				</div>
			</div>
		</>
	);
}